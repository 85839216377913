// @flow

import { compose, setDisplayName, withHandlers } from "recompose";
import { useTheme, withStyles } from "@mui/styles";
import { withRouter } from "found";
import AppLauncherAppBar from "./components/AppLauncherAppBar";
import AppLauncherCard from "./components/AppLauncherCard";
import ArtImage from "../../../assets/art.svg";
import ArtworkProjectionContext from "../../ArtworkProjectionContext";
import DTGImage from "../../../assets/dtg.svg";
import EmbroideryImage from "../../../assets/embroidery.svg";
import Grid from "@mui/material/Grid";
import HDDPressImage from "../../../assets/dtg.svg";
import HDDReadyImage from "../../../assets/dtg.svg";
import LocalInventoryImage from "../../../assets/pulledStock.svg";
import PulledStockImage from "../../../assets/pulledStock.svg";
import React, { useContext } from "react";
import ReceivingImage from "../../../assets/receiving.svg";
import ScreenPrintingImage from "../../../assets/screenPrinting.svg";
import ScreensMadeImage from "../../../assets/screensMade.svg";
import StockStagedImage from "../../../assets/stockStaged.svg";
import VinylImage from "../../../assets/vinyl.svg";
import useMediaQuery from "@mui/material/useMediaQuery";

import type { HOC } from "recompose";

const { REACT_APP_INVENTORY_MANAGEMENT_URL } = process.env;

type Props = {|
  +isWidthUp: {
    +width: string
  },
  +router: {|
    +push: (location: string) => void
  |}
|};

const styles = theme => ({
  container: {
    margin: theme.spacing(3)
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("AppLauncher"),
  withRouter,
  withStyles(styles),

  withHandlers({
    handleAppCardClick:
      ({ router }) =>
      (location: string) => {
        if (location.toLowerCase().startsWith("https://")) {
          window.location = location;
        } else {
          router.push(location);
        }
      }
  })
);

const appButtons = [
  {
    location: "/apps/screens-made",
    name: "Screens Made",
    appImage: ScreensMadeImage
  },
  {
    location: "/apps/receiving",
    name: "Receiving",
    appImage: ReceivingImage
  },
  {
    location: "/apps/local-inventory",
    name: "Local Inventory",
    appImage: LocalInventoryImage
  },
  {
    location: `${REACT_APP_INVENTORY_MANAGEMENT_URL}/inventory`,
    name: "Inventory Management",
    appImage: LocalInventoryImage
  },
  {
    location: "/apps/pull-from-stock",
    name: "Pull From Stock",
    appImage: PulledStockImage
  },
  {
    location: "/apps/counting-staging",
    name: "Counting/Staging",
    appImage: StockStagedImage
  },
  {
    location: "/apps/production/embroidery",
    name: "Embroidery",
    appImage: EmbroideryImage
  },
  {
    location: "/apps/production/dtg",
    name: "DTG",
    appImage: DTGImage
  },
  {
    location: "/apps/production/hdd-press",
    name: "HDD Press",
    appImage: HDDPressImage
  },
  {
    location: "/apps/production/hdd-merging",
    name: "HDD Merging",
    appImage: HDDReadyImage
  },
  {
    location: "/apps/production/transfer-printing/standard",
    name: "Transfer Printing",
    appImage: HDDPressImage // TODO:
  },
  {
    location: "/apps/production/screen-printing",
    name: "Screen Printing",
    appImage: ScreenPrintingImage
  },
  {
    location: "/apps/production/vinyl",
    name: "Vinyl",
    appImage: VinylImage
  },
  {
    location: "/apps/production/proofing",
    name: "Proofing",
    appImage: ArtImage
  },
  {
    location: "/apps/production/art",
    name: "Art",
    appImage: ArtImage
  },
  {
    location: "/apps/check-return-status",
    name: "Check Return Status",
    appImage: ReceivingImage
  },
  {
    location: "/apps/bulk-location-setter",
    name: "Bulk Location Setter",
    appImage: ReceivingImage
  },
  {
    location: "/apps/art-ready",
    name: "Art Ready",
    appImage: ArtImage
  },
  {
    location: "/apps/film-check",
    name: "Film Check",
    appImage: HDDReadyImage
  },
  {
    location: "/apps/fix-pieces",
    name: "Fix Pieces",
    appImage: ReceivingImage
  }
];

const AppLauncher = ({ classes, handleAppCardClick }) => {
  const theme = useTheme();
  const { launchArtworkProjection } = useContext(ArtworkProjectionContext);
  const flexAlignment = useMediaQuery(theme.breakpoints.up("md"))
    ? "flex-start"
    : "center";
  return (
    <div>
      <AppLauncherAppBar />
      <div className={classes.container}>
        <Grid
          container
          spacing={1}
          justify={flexAlignment}
          alignItems={flexAlignment}
        >
          {appButtons.map(({ location, name, appImage }) => (
            <Grid key={name} item md={4} lg={3} xl={2} sm={6}>
              <AppLauncherCard
                location={location}
                title={name}
                appImage={appImage}
                handleAppCardClick={handleAppCardClick.bind(null, location)}
              />
            </Grid>
          ))}
          <Grid item md={4} lg={3} xl={2} sm={6}>
            <AppLauncherCard
              title={"Launch Artwork Projection"}
              appImage={HDDReadyImage}
              handleAppCardClick={launchArtworkProjection}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default enhancer(AppLauncher);
