// @flow

import { gql } from "@apollo/client";

export default {
  order: gql`
    fragment TransfersGlitterOrderStatus_order on Order {
      id
      transfersGlitterStatus
      canViewerStartTransfersGlitterManualRipping
      canViewerStartTransfersGlitterManualPrinting
      canViewerCompleteTransfersGlitterManualRipping
      canViewerCompleteTransfersGlitterManualPrinting
      canViewerTrimTransfersGlitter
    }
  `
};
