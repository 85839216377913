// @flow

import { compose, setDisplayName, withHandlers } from "recompose";
import { useTheme, withStyles } from "@mui/styles";
import { withRouter } from "found";
import AppLauncherAppBar from "./components/AppLauncherAppBar";
import AppLauncherCard from "./components/AppLauncherCard";
import Grid from "@mui/material/Grid";
import HDDPressImage from "../../../assets/dtg.svg";
import HDDReadyImage from "../../../assets/dtg.svg";
import LocalInventoryImage from "../../../assets/pulledStock.svg";
import React from "react";
import ReceivingImage from "../../../assets/receiving.svg";
import StockStagedImage from "../../../assets/stockStaged.svg";
import useMediaQuery from "@mui/material/useMediaQuery";

import type { HOC } from "recompose";

const { REACT_APP_INVENTORY_MANAGEMENT_URL } = process.env;

type Props = {|
  +isWidthUp: {
    +width: string
  },
  +router: {|
    +push: (location: string) => void
  |}
|};

const styles = theme => ({
  container: {
    margin: theme.spacing(3)
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("AppLauncher"),
  withRouter,
  withStyles(styles),

  withHandlers({
    handleAppCardClick:
      ({ router }) =>
      (location: string) => {
        if (location.toLowerCase().startsWith("https://")) {
          window.open(location, "_blank");
        } else {
          router.push(location);
        }
      }
  })
);

const appButtons = [
  {
    location: "/mach6/apps/receiving",
    name: "Receiving",
    appImage: ReceivingImage
  },
  {
    location: "/mach6/apps/production/local-inventory",
    name: "Local Inventory",
    appImage: LocalInventoryImage
  },
  {
    location: `${REACT_APP_INVENTORY_MANAGEMENT_URL}/inventory`,
    name: "Inventory Management",
    appImage: LocalInventoryImage
  },
  {
    location: "/mach6/apps/counting-staging",
    name: "Counting / Staging",
    appImage: StockStagedImage
  },
  {
    location: "/mach6/apps/production/hdd-merging",
    name: "HDD Merging",
    appImage: HDDReadyImage
  },
  {
    location: "/mach6/apps/production/hdd-press",
    name: "HDD Pressing",
    appImage: HDDPressImage
  },
  {
    location: "/apps/customs/southbound",
    name: "Customs - Southbound",
    appImage: ReceivingImage
  },
  {
    location: "/apps/customs/northbound",
    name: "Customs - Northbound",
    appImage: ReceivingImage
  },
  {
    location: "/mach6/apps/bulk-location-setter",
    name: "Bulk Location Setter",
    appImage: ReceivingImage
  },
  {
    location: "/mach6/apps/check-return-status",
    name: "Audit Goods Status",
    appImage: ReceivingImage
  },
  {
    location: "/mach6/apps/film-check",
    name: "Audit Film Status",
    appImage: HDDReadyImage
  }
];

const AppLauncher = ({ classes, handleAppCardClick }) => {
  const theme = useTheme();
  const flexAlignment = useMediaQuery(theme.breakpoints.up("md"))
    ? "flex-start"
    : "center";
  return (
    <div>
      <AppLauncherAppBar />
      <div className={classes.container}>
        <Grid
          container
          spacing={1}
          justify={flexAlignment}
          alignItems={flexAlignment}
        >
          {appButtons.map(({ location, name, appImage }) => (
            <Grid key={name} item md={4} lg={3} xl={2} sm={6}>
              <AppLauncherCard
                location={location}
                title={name}
                appImage={appImage}
                handleAppCardClick={handleAppCardClick.bind(null, location)}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default enhancer(AppLauncher);
