// @flow

import * as React from "react";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { getLabelForTransfersUVStatus } from "../../../../../helpers";
import {
  markOrderAsTransfersUVManualPrintingComplete,
  markOrderAsTransfersUVManualPrintingPaused,
  markOrderAsTransfersUVManualPrintingStarted,
  markOrderAsTransfersUVManualRippingComplete,
  markOrderAsTransfersUVManualRippingStarted,
  markOrderAsTransfersUVTrimmingCompleted
} from "../../../../../graph";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import JobStatusIcon from "../../../../../components/JobStatusIcon";
import Typography from "@mui/material/Typography";
import withSnackbar from "../../../../../components/withSnackbar";

import type { HOC } from "recompose";

type Props = {|
  +classes?: {|
    +buttonContainer: string,
    +jobStatus: string,
    +spaceBetweenContainer: string,
    +spacerRight: string,
    +spinnerWrapper: string
  |},
  +order: {|
    +canViewerCompleteHighDefinitionDigital: boolean,
    +canViewerPlaceOnHold: boolean,
    +canViewerRemoveHold: boolean,
    +canViewerStartHighDefinitionDigital: boolean,
    +highDefinitionDigitalStatus: string,
    +id: string
  |}
|};

type State = {|
  isMutatingOrder: boolean,
  isPlacingOrderOnHold: boolean
|};

const styles = theme => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: theme.spacing(1)
  },

  jobStatus: {
    marginLeft: theme.spacing(1)
  },

  spaceBetweenContainer: {
    display: "flex"
  },

  spacerRight: {
    marginRight: theme.spacing(1)
  },

  spinnerWrapper: {
    padding: theme.spacing(1),
    position: "relative",
    textAlign: "center"
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("TransfersUVOrderStatus"),

  markOrderAsTransfersUVManualRippingComplete,

  markOrderAsTransfersUVManualRippingStarted,

  markOrderAsTransfersUVManualPrintingComplete,

  markOrderAsTransfersUVManualPrintingPaused,

  markOrderAsTransfersUVManualPrintingStarted,

  markOrderAsTransfersUVTrimmingCompleted,

  withSnackbar,

  withStyles(styles),

  withStateHandlers(({ isMutatingOrder: false }: State), {
    setIsMutatingOrder: () => (isMutatingOrder: boolean) => ({
      isMutatingOrder
    })
  }),

  withHandlers({
    
    handleStartRippingButtonClick:
      ({
        markOrderAsTransfersUVManualRippingStarted,
        order: { id: orderId },
        setIsMutatingOrder,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      () => {
        setIsMutatingOrder(true);
        markOrderAsTransfersUVManualRippingStarted({ variables: { orderId } })
          .then(
            ({
              data: {
                markOrderAsTransfersUvManualRippingStarted: { succeeded, errors }
              }
            }) => {
              if (succeeded) {
                showSuccessSnackbar(`The order has been updated`);
              } else {
                showErrorSnackbar(
                  `Something went wrong: ${errors.orderId.join(", ")}`
                );
              }
            }
          )
          .catch(e => {
            showErrorSnackbar(`Something went wrong: ${e.message}`);
          })
          .finally(() => setIsMutatingOrder(false));
      },

    handleCompleteRippingButtonClick:
      ({
        markOrderAsTransfersUVManualRippingCompleted,
        order: { id: orderId },
        setIsMutatingOrder,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      () => {
        setIsMutatingOrder(true);
        markOrderAsTransfersUVManualRippingCompleted({ variables: { orderId } })
          .then(
            ({
              data: {
                markOrderAsTransfersUvManualRippingCompleted: { succeeded, errors }
              }
            }) => {
              if (succeeded) {
                showSuccessSnackbar(`The order has been updated`);
              } else {
                showErrorSnackbar(
                  `Something went wrong: ${errors.orderId.join(", ")}`
                );
              }
            }
          )
          .catch(e => {
            showErrorSnackbar(`Something went wrong: ${e.message}`);
          })
          .finally(() => setIsMutatingOrder(false));
      },

    handleStartPrintingButtonClick:
        ({
          markOrderAsTransfersUVManualPrintingStarted,
          order: { id: orderId },
          setIsMutatingOrder,
          showErrorSnackbar,
          showSuccessSnackbar
        }) =>
        () => {
          setIsMutatingOrder(true);
          markOrderAsTransfersUVManualPrintingStarted({ variables: { orderId } })
            .then(
              ({
                data: {
                  markOrderAsTransfersUvManualPrintingStarted: { succeeded, errors }
                }
              }) => {
                if (succeeded) {
                  showSuccessSnackbar(`The order has been updated`);
                } else {
                  showErrorSnackbar(
                    `Something went wrong: ${errors.orderId.join(", ")}`
                  );
                }
              }
            )
            .catch(e => {
              showErrorSnackbar(`Something went wrong: ${e.message}`);
            })
            .finally(() => setIsMutatingOrder(false));
        },

        handlePausedPrintingButtonClick:
        ({
          markOrderAsTransfersUVManualPrintingPaused,
          order: { id: orderId },
          setIsMutatingOrder,
          showErrorSnackbar,
          showSuccessSnackbar
        }) =>
        () => {
          setIsMutatingOrder(true);
          markOrderAsTransfersUVManualPrintingPaused({ variables: { orderId } })
            .then(
              ({
                data: {
                  markOrderAsTransfersUvManualPrintingPaused: { succeeded, errors }
                }
              }) => {
                if (succeeded) {
                  showSuccessSnackbar(`The order has been updated`);
                } else {
                  showErrorSnackbar(
                    `Something went wrong: ${errors.orderId.join(", ")}`
                  );
                }
              }
            )
            .catch(e => {
              showErrorSnackbar(`Something went wrong: ${e.message}`);
            })
            .finally(() => setIsMutatingOrder(false));
        },

        handleCompletePrintingButtonClick:
            ({
              markOrderAsTransfersUVManualPrintingComplete,
              order: { id: orderId },
              setIsMutatingOrder,
              showErrorSnackbar,
              showSuccessSnackbar
            }) =>
            () => {
              setIsMutatingOrder(true);
              markOrderAsTransfersUVManualPrintingComplete({ variables: { orderId } })
                .then(
                  ({
                    data: {
                      markOrderAsTransfersUvManualPrintingComplete: { succeeded, errors }
                    }
                  }) => {
                    if (succeeded) {
                      showSuccessSnackbar(`The order has been updated`);
                    } else {
                      showErrorSnackbar(
                        `Something went wrong: ${errors.orderId.join(", ")}`
                      );
                    }
                  }
                )
                .catch(e => {
                  showErrorSnackbar(`Something went wrong: ${e.message}`);
                })
                .finally(() => setIsMutatingOrder(false));
            },

            handleCompleteTrimmingButtonClick:
                ({
                  markOrderAsTransfersUVTrimmingCompleted,
                  order: { id: orderId },
                  setIsMutatingOrder,
                  showErrorSnackbar,
                  showSuccessSnackbar
                }) =>
                () => {
                  setIsMutatingOrder(true);
                  markOrderAsTransfersUVTrimmingCompleted({ variables: { orderId } })
                    .then(
                      ({
                        data: {
                          markOrderAsTransfersUvTrimmingCompleted: { succeeded, errors }
                        }
                      }) => {
                        if (succeeded) {
                          showSuccessSnackbar(`The order has been updated`);
                        } else {
                          showErrorSnackbar(
                            `Something went wrong: ${errors.orderId.join(", ")}`
                          );
                        }
                      }
                    )
                    .catch(e => {
                      showErrorSnackbar(`Something went wrong: ${e.message}`);
                    })
                    .finally(() => setIsMutatingOrder(false));
                }
  })
);

const TransfersUVOrderStatus = ({
  classes,
  handleCompleteRippingButtonClick,
  handleStartRippingButtonClick,
  handleCompletePrintingButtonClick,
  handlePausePrintingButtonClick,
  handleStartPrintingButtonClick,
  handleCompleteTrimmingButtonClick,
  isMutatingOrder,
  order: {
    transfersUvStatus,
    canViewerStartTransfersUvManualRipping,
    canViewerCompleteTransfersUvManualRipping,
    canViewerStartTransfersUvManualPrinting,
    canViewerPauseTransfersUvManualPrinting,
    canViewerCompleteTransfersUvManualPrinting,
    canViewerTrimTransfersUv
  }
}) => (
  <div>
    <div className={classes.spaceBetweenContainer}>
      <JobStatusIcon status={transfersUvStatus} />
      <div className={classes.jobStatus}>
        <Typography variant="h6">Transfers - UV</Typography>
        <Typography variant="body2">
          {getLabelForTransfersUVStatus(transfersUvStatus)}
        </Typography>
      </div>
    </div>
    {isMutatingOrder ? (
      <div className={classes.spinnerWrapper}>
        <CircularProgress />
      </div>
    ) : (
      <div className={classes.buttonContainer}>
        {canViewerStartTransfersUvManualRipping && (
          <Button
            className={classes.spacerRight}
            color="primary"
            variant="contained"
            onClick={handleStartRippingButtonClick}
          >
            Start Ripping
          </Button>
        )}
        {canViewerCompleteTransfersUvManualRipping && ( // TODO: Fix this bit in graph
          <Button
            className={classes.spacerRight}
            color="primary"
            variant="contained"
            onClick={handleCompleteRippingButtonClick}
          >
            Complete Ripping
          </Button>
        )}
        {canViewerStartTransfersUvManualPrinting && (
          <Button
            className={classes.spacerRight}
            color="primary"
            variant="contained"
            onClick={handleStartPrintingButtonClick}
          >
            Start Printing
          </Button>
        )}
        {canViewerPauseTransfersUvManualPrinting && (
          <Button
            className={classes.spacerRight}
            color="primary"
            variant="contained"
            onClick={handlePausePrintingButtonClick}
          >
            Pause Printing
          </Button>
        )}
        {canViewerCompleteTransfersUvManualPrinting && (
          <Button
            className={classes.spacerRight}
            color="primary"
            variant="contained"
            onClick={handleCompletePrintingButtonClick}
          >
            Complete Printing
          </Button>
        )}
        {canViewerTrimTransfersUv && (
          <Button
            className={classes.spacerRight}
            color="primary"
            variant="contained"
            onClick={handleCompleteTrimmingButtonClick}
          >
            Complete Trimming
          </Button>
        )}
      </div>
    )}
  </div>
);

export default enhancer(TransfersUVOrderStatus);
