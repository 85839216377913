// @flow

import * as React from "react";
import { DateTime } from "luxon";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";
import { gql } from "@apollo/client";
import { markOrderAsDtgCompleted } from "../../graph";
import { markOrderAsDtgStarted } from "../../graph";
import { markOrderAsHighDefinitionDigitalCompleted } from "../../graph";
import { markOrderAsHighDefinitionDigitalLocated } from "../../graph";
import { markOrderAsHighDefinitionDigitalPaused } from "../../graph";
import { markOrderAsHighDefinitionDigitalStarted } from "../../graph";
import { markOrderAsHighDefinitionDigitalTrimmed } from "../../graph";
import { markOrderAsNoLongerOnHold } from "../../graph";
import { markOrderAsOnHold } from "../../graph";
import { markOrderAsScreenPrintingCompleted } from "../../graph";
import { markOrderAsScreenPrintingStarted } from "../../graph";
import { markOrderAsStockStaged } from "../../graph";
import { markOrderAsTagPrintCompleted } from "../../graph";
import { markOrderAsTransfersGlitterManualPrintingComplete } from "../../graph";
import { markOrderAsTransfersGlitterTrimmingCompleted } from "../../graph";
import { markOrderAsTransfersStandardManualPrintingComplete } from "../../graph";
import { markOrderAsTransfersStandardTrimmingCompleted } from "../../graph";
import { markOrderAsTransfersUVManualPrintingComplete } from "../../graph";
import { markOrderAsTransfersUVTrimmingCompleted } from "../../graph";
import { markOrderAsUnstaged } from "../../graph";
import { markOrderProductionJobAsCountValidated } from "../../graph";
import { markOrderProductionJobAsStockPulled } from "../../graph";
import { playAudioForSuccess } from "../../helpers/playAudioForSuccess";
import { recordOrderProductionJobBoxLocation } from "../../graph";
import { recordOrderProductionJobScreenLocation } from "../../graph";
import { recordStockContainerLocation } from "../../graph";
import { setStockLocationParent } from "../../graph";
import { withApollo } from "@apollo/client/react/hoc";
import BarcodeReader from "react-barcode-reader";
import withSnackbar from "../withSnackbar";

import type { HOC } from "recompose";
import type { HighDefinitionDigitalStatus } from "../../types/HighDefinitionDigitalStatus";

type ScannedOrderProductionJob = {|
  +id: string,
  +order: {|
    +highDefinitionDigitalStatus: HighDefinitionDigitalStatus,
    +id: string
  |}
|};

type State = {|
  lastOrderProductionJobScanned: ?ScannedOrderProductionJob,
  lastStockContainerScanned: ?Object,
  scannedObjects: Array<{}>
|};

const BarcodePivotContext = React.createContext<{|
  acknowledgeOrderProductionJob: () => void,
  acknowledgeStockContainer: () => void,
  lastOrderProductionJobScanned: ?ScannedOrderProductionJob,
  lastStockContainerScanned: ?Object
|}>({
  acknowledgeOrderProductionJob: () => {},
  acknowledgeStockContainer: () => {},
  lastOrderProductionJobScanned: null,
  lastStockContainerScanned: null
});

const ORDER_QUERY = gql`
  query BarcodeOrderQuery($orderProductionJobId: ID!) {
    node(id: $orderProductionJobId) {
      ... on OrderProductionJob {
        id
        order {
          id
          highDefinitionDigitalStatus
        }
      }
    }
  }
`;

const enhancer: HOC<*, *> = compose(
  setDisplayName("BarcodePivot"),

  withApollo,

  markOrderAsDtgCompleted,

  markOrderAsDtgStarted,

  markOrderAsHighDefinitionDigitalCompleted,

  markOrderAsHighDefinitionDigitalPaused,

  markOrderAsHighDefinitionDigitalLocated,

  markOrderAsHighDefinitionDigitalStarted,

  markOrderAsHighDefinitionDigitalTrimmed,

  markOrderAsNoLongerOnHold,

  markOrderAsOnHold,

  markOrderAsScreenPrintingStarted,

  markOrderAsScreenPrintingCompleted,

  markOrderAsTransfersStandardManualPrintingComplete,

  markOrderAsTransfersGlitterManualPrintingComplete,

  markOrderAsTransfersUVManualPrintingComplete,

  markOrderAsTransfersStandardTrimmingCompleted,

  markOrderAsTransfersGlitterTrimmingCompleted,

  markOrderAsTransfersUVTrimmingCompleted,

  markOrderProductionJobAsCountValidated,

  recordOrderProductionJobBoxLocation,

  recordOrderProductionJobScreenLocation,

  recordStockContainerLocation,

  setStockLocationParent,

  markOrderAsStockStaged(),

  markOrderAsUnstaged,

  markOrderAsTagPrintCompleted,

  markOrderProductionJobAsStockPulled(),

  withSnackbar,

  withStateHandlers(
    ({
      scannedObjects: [],
      lastOrderProductionJobScanned: null,
      lastStockContainerScanned: null
    }: State),
    {
      resetLastOrderProductionJobScanned: () => () => ({
        lastOrderProductionJobScanned: null
      }),
      resetScannedObjects: () => () => ({
        scannedObjects: []
      }),
      resetLastStockContainerScanned: () => () => ({
        lastStockContainerScanned: null
      }),
      setLastOrderProductionJobScanned:
        () => lastOrderProductionJobScanned => ({
          lastOrderProductionJobScanned
        }),
      setLastStockContainerScanned: () => lastStockContainerScanned => ({
        lastStockContainerScanned
      })
    }
  ),

  withHandlers({
    handleBarcodeError:
      ({ showErrorSnackbar }) =>
      () => {
        showErrorSnackbar("Error scanning barcode");
      },

    handleMarkOrderAsTagPrintCompleted:
      ({
        client,
        markOrderAsTagPrintCompleted,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      orderProductionJobId => {
        client
          .query({
            query: ORDER_QUERY,
            variables: { orderProductionJobId }
          })
          .then(
            ({
              data: {
                node: {
                  order: { id }
                }
              }
            }) => {
              markOrderAsTagPrintCompleted({ variables: { orderId: id } })
                .then(
                  ({
                    data: {
                      markOrderAsTagPrintCompleted: { succeeded, errors }
                    }
                  }) => {
                    if (succeeded) {
                      showSuccessSnackbar("Order has been tag printed.");
                    } else {
                      showErrorSnackbar(errors.orderId.join(", "));
                    }
                  }
                )
                .catch(e => {
                  showErrorSnackbar(e.message);
                });
            }
          );
      },

    handleMarkOrderAsNoLongerOnHold:
      ({
        client,
        markOrderAsNoLongerOnHold,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      orderProductionJobId => {
        client
          .query({
            query: ORDER_QUERY,
            variables: { orderProductionJobId }
          })
          .then(
            ({
              data: {
                node: {
                  order: { id }
                }
              }
            }) => {
              markOrderAsNoLongerOnHold({
                variables: { orderId: id }
              })
                .then(
                  ({
                    data: {
                      markOrderAsNoLongerOnHold: { succeeded, errors }
                    }
                  }) => {
                    if (succeeded) {
                      showSuccessSnackbar(
                        "Order has been marked on no longer on hold."
                      );
                    } else {
                      showErrorSnackbar(errors.orderId.join(", "));
                    }
                  }
                )
                .catch(e => {
                  showErrorSnackbar(e.message);
                });
            }
          );
      },

    handleMarkOrderAsOnHold:
      ({ client, markOrderAsOnHold, showErrorSnackbar, showSuccessSnackbar }) =>
      orderProductionJobId => {
        client
          .query({
            query: ORDER_QUERY,
            variables: { orderProductionJobId }
          })
          .then(
            ({
              data: {
                node: {
                  order: { id }
                }
              }
            }) => {
              markOrderAsOnHold({
                variables: { orderId: id, note: "Order on hold!" }
              })
                .then(
                  ({
                    data: {
                      markOrderAsOnHold: { succeeded, errors }
                    }
                  }) => {
                    if (succeeded) {
                      showSuccessSnackbar("Order has been marked on hold.");
                    } else {
                      showErrorSnackbar(errors.orderId.join(", "));
                    }
                  }
                )
                .catch(e => {
                  showErrorSnackbar(e.message);
                });
            }
          );
      },

    handleMarkOrderAsHighDefinitionDigitalLocated:
      ({
        client,
        markOrderAsHighDefinitionDigitalLocated,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      orderProductionJobId => {
        client
          .query({
            query: ORDER_QUERY,
            variables: { orderProductionJobId }
          })
          .then(
            ({
              data: {
                node: {
                  order: { id }
                }
              }
            }) => {
              markOrderAsHighDefinitionDigitalLocated({
                variables: { orderId: id }
              })
                .then(
                  ({
                    data: {
                      markOrderAsHighDefinitionDigitalLocated: {
                        succeeded,
                        errors
                      }
                    }
                  }) => {
                    if (succeeded) {
                      showSuccessSnackbar("Order has been located.");
                    } else {
                      showErrorSnackbar(errors.orderId.join(", "));
                    }
                  }
                )
                .catch(e => {
                  showErrorSnackbar(e.message);
                });
            }
          );
      },

    markOrderAsHighDefinitionDigitalPaused:
      ({
        client,
        markOrderAsHighDefinitionDigitalPaused,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      orderProductionJobId => {
        client
          .query({
            query: ORDER_QUERY,
            variables: { orderProductionJobId }
          })
          .then(
            ({
              data: {
                node: {
                  order: { id }
                }
              }
            }) => {
              markOrderAsHighDefinitionDigitalPaused({
                variables: { orderId: id }
              })
                .then(
                  ({
                    data: {
                      markOrderAsHighDefinitionDigitalPaused: {
                        succeeded,
                        errors
                      }
                    }
                  }) => {
                    if (succeeded) {
                      showSuccessSnackbar("Order has been paused.");
                    } else {
                      showErrorSnackbar(errors.orderId.join(", "));
                    }
                  }
                )
                .catch(e => {
                  showErrorSnackbar(e.message);
                });
            }
          );
      },

    handleMarkOrderAsHighDefinitionDigitalCompleted:
      ({
        client,
        markOrderAsHighDefinitionDigitalStarted,
        markOrderAsHighDefinitionDigitalCompleted,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      orderProductionJobId => {
        client
          .query({
            query: ORDER_QUERY,
            variables: { orderProductionJobId }
          })
          .then(
            ({
              data: {
                node: {
                  order: { id, highDefinitionDigitalStatus }
                }
              }
            }) => {
              return new Promise((resolve, reject) => {
                if (highDefinitionDigitalStatus === "STAGED") {
                  markOrderAsHighDefinitionDigitalStarted({
                    variables: { orderId: id }
                  })
                    .then(
                      ({
                        data: {
                          markOrderAsHighDefinitionDigitalStarted: {
                            succeeded,
                            errors
                          }
                        }
                      }) => {
                        if (succeeded) {
                          resolve();
                        } else {
                          showErrorSnackbar(errors.orderId.join(", "));
                          reject();
                        }
                      }
                    )
                    .catch(e => {
                      showErrorSnackbar(e.message);
                      reject();
                    });
                } else {
                  resolve();
                }
              }).then(() => {
                markOrderAsHighDefinitionDigitalCompleted({
                  variables: { orderId: id }
                })
                  .then(
                    ({
                      data: {
                        markOrderAsHighDefinitionDigitalCompleted: {
                          succeeded,
                          errors
                        }
                      }
                    }) => {
                      if (succeeded) {
                        showSuccessSnackbar("Order has been completed.");
                      } else {
                        showErrorSnackbar(errors.orderId.join(", "));
                      }
                    }
                  )
                  .catch(e => {
                    showErrorSnackbar(e.message);
                  });
              });
            }
          );
      },

    handleMarkOrderAsHighDefinitionDigitalStarted:
      ({
        client,
        markOrderAsHighDefinitionDigitalStarted,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      orderProductionJobId => {
        client
          .query({
            query: ORDER_QUERY,
            variables: { orderProductionJobId }
          })
          .then(
            ({
              data: {
                node: {
                  order: { id }
                }
              }
            }) => {
              markOrderAsHighDefinitionDigitalStarted({
                variables: { orderId: id }
              })
                .then(
                  ({
                    data: {
                      markOrderAsHighDefinitionDigitalStarted: {
                        succeeded,
                        errors
                      }
                    }
                  }) => {
                    if (succeeded) {
                      showSuccessSnackbar("Order has been started.");
                    } else {
                      showErrorSnackbar(errors.orderId.join(", "));
                    }
                  }
                )
                .catch(e => {
                  showErrorSnackbar(e.message);
                });
            }
          );
      },

    handleMarkOrderAsHighDefinitionDigitalTrimmed:
      ({
        client,
        markOrderAsHighDefinitionDigitalTrimmed,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      orderProductionJobId => {
        client
          .query({
            query: ORDER_QUERY,
            variables: { orderProductionJobId }
          })
          .then(
            ({
              data: {
                node: {
                  order: { id }
                }
              }
            }) => {
              markOrderAsHighDefinitionDigitalTrimmed({
                variables: { orderId: id }
              })
                .then(
                  ({
                    data: {
                      markOrderAsHighDefinitionDigitalTrimmed: {
                        succeeded,
                        errors
                      }
                    }
                  }) => {
                    if (succeeded) {
                      showSuccessSnackbar("Order has been trimmed.");
                    } else {
                      showErrorSnackbar(errors.orderId.join(", "));
                    }
                  }
                )
                .catch(e => {
                  showErrorSnackbar(e.message);
                });
            }
          );
      },

    handleMarkOrderProductionJobAsCountValidated:
      ({
        markOrderProductionJobAsCountValidated,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      orderProductionJobId => {
        markOrderProductionJobAsCountValidated({
          variables: { orderProductionJobId }
        })
          .then(
            ({
              data: {
                markOrderProductionJobAsCountValidated: { succeeded, errors }
              }
            }) => {
              if (succeeded) {
                showSuccessSnackbar(
                  "Order production job has been count validated."
                );
              } else {
                showErrorSnackbar(errors.orderProductionJobId.join(", "));
              }
            }
          )
          .catch(e => {
            showErrorSnackbar(e.message);
          });
      },

    handleRecordOrderProductionJobBoxLocation:
      ({
        recordOrderProductionJobBoxLocation,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      (location, orderProductionJobId) => {
        recordOrderProductionJobBoxLocation({
          variables: { location, orderProductionJobId }
        })
          .then(
            ({
              data: {
                recordOrderProductionJobBoxLocation: { succeeded, errors }
              }
            }) => {
              if (succeeded) {
                showSuccessSnackbar(
                  "Order production job box location has been recorded."
                );
                playAudioForSuccess();
              } else {
                showErrorSnackbar(errors.orderProductionJobId.join(", "));
              }
            }
          )
          .catch(e => {
            showErrorSnackbar(e.message);
          });
      },

    handleRecordOrderProductionJobScreenLocation:
      ({
        markOrderAsHighDefinitionDigitalLocated,
        recordOrderProductionJobScreenLocation,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      (location, orderProductionJobId) => {
        recordOrderProductionJobScreenLocation({
          variables: { location, orderProductionJobId }
        })
          .then(
            ({
              data: {
                recordOrderProductionJobScreenLocation: {
                  succeeded,
                  errors,
                  updatedOrderProductionJob: {
                    order: { id: orderId, highDefinitionDigitalStatus }
                  }
                }
              }
            }) => {
              if (succeeded) {
                if (
                  highDefinitionDigitalStatus !== "NOT_APPLICABLE" &&
                  highDefinitionDigitalStatus !== "LOCATED"
                ) {
                  return markOrderAsHighDefinitionDigitalLocated({
                    variables: { orderId }
                  }).then(
                    ({
                      data: {
                        markOrderAsHighDefinitionDigitalLocated: {
                          succeeded,
                          errors
                        }
                      }
                    }) => {
                      if (succeeded) {
                        showSuccessSnackbar(
                          "Order production job screen location has been recorded."
                        );
                        playAudioForSuccess();
                      } else {
                        showErrorSnackbar(errors.orderId.join(", "));
                      }
                    }
                  );
                } else {
                  showSuccessSnackbar(
                    "Order production job screen location has been recorded."
                  );
                  playAudioForSuccess();
                }
              } else {
                showErrorSnackbar(errors.orderProductionJobId.join(", "));
              }
            }
          )
          .catch(e => {
            showErrorSnackbar(e.message);
          });
      },

    handleRecordStockContainerLocation:
      ({
        recordStockContainerLocation,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      (location, stockContainerId) => {
        recordStockContainerLocation({
          variables: { location, stockContainerId }
        })
          .then(
            ({
              data: {
                recordStockContainerLocation: { succeeded, errors }
              }
            }) => {
              if (succeeded) {
                showSuccessSnackbar("Box location has been recorded.");
                playAudioForSuccess();
              } else {
                showErrorSnackbar(errors.stockContainerId.join(", "));
              }
            }
          )
          .catch(e => {
            showErrorSnackbar(e.message);
          });
      },

    handleMarkOrderAsDtgStarted:
      ({
        client,
        markOrderAsDtgStarted,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      orderProductionJobId => {
        client
          .query({
            query: ORDER_QUERY,
            variables: { orderProductionJobId }
          })
          .then(
            ({
              data: {
                node: {
                  order: { id }
                }
              }
            }) => {
              markOrderAsDtgStarted({ variables: { orderId: id } })
                .then(
                  ({
                    data: {
                      markOrderAsDtgStarted: { succeeded, errors }
                    }
                  }) => {
                    if (succeeded) {
                      showSuccessSnackbar("Order has started DTG");
                    } else {
                      showErrorSnackbar(errors.orderId.join(", "));
                    }
                  }
                )
                .catch(e => {
                  showErrorSnackbar(e.message);
                });
            }
          );
      },

    handleMarkOrderAsDtgCompleted:
      ({
        client,
        markOrderAsDtgCompleted,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      orderProductionJobId => {
        client
          .query({
            query: ORDER_QUERY,
            variables: { orderProductionJobId }
          })
          .then(
            ({
              data: {
                node: {
                  order: { id }
                }
              }
            }) => {
              markOrderAsDtgCompleted({ variables: { orderId: id } })
                .then(
                  ({
                    data: {
                      markOrderAsDtgCompleted: { succeeded, errors }
                    }
                  }) => {
                    if (succeeded) {
                      showSuccessSnackbar("Order has completed DTG");
                    } else {
                      showErrorSnackbar(errors.orderId.join(", "));
                    }
                  }
                )
                .catch(e => {
                  showErrorSnackbar(e.message);
                });
            }
          );
      },

    handleMarkOrderAsScreenPrintingStarted:
      ({
        client,
        markOrderAsScreenPrintingStarted,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      orderProductionJobId => {
        client
          .query({
            query: ORDER_QUERY,
            variables: { orderProductionJobId }
          })
          .then(
            ({
              data: {
                node: {
                  order: { id }
                }
              }
            }) => {
              markOrderAsScreenPrintingStarted({ variables: { orderId: id } })
                .then(
                  ({
                    data: {
                      markOrderAsScreenPrintingStarted: { succeeded, errors }
                    }
                  }) => {
                    if (succeeded) {
                      showSuccessSnackbar("Order has started Screen Printing.");
                    } else {
                      showErrorSnackbar(errors.orderId.join(", "));
                    }
                  }
                )
                .catch(e => {
                  showErrorSnackbar(e.message);
                });
            }
          );
      },

    handleMarkOrderAsScreenPrintingCompleted:
      ({
        client,
        markOrderAsScreenPrintingCompleted,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      orderProductionJobId => {
        client
          .query({
            query: ORDER_QUERY,
            variables: { orderProductionJobId }
          })
          .then(
            ({
              data: {
                node: {
                  order: { id }
                }
              }
            }) => {
              markOrderAsScreenPrintingCompleted({ variables: { orderId: id } })
                .then(
                  ({
                    data: {
                      markOrderAsScreenPrintingCompleted: { succeeded, errors }
                    }
                  }) => {
                    if (succeeded) {
                      showSuccessSnackbar(
                        "Order has completed Screen Printing."
                      );
                    } else {
                      showErrorSnackbar(errors.orderId.join(", "));
                    }
                  }
                )
                .catch(e => {
                  showErrorSnackbar(e.message);
                });
            }
          );
      },

    handleMarkOrderAsStockStaged:
      ({
        client,
        markOrderAsStockStaged,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      orderProductionJobId => {
        client
          .query({
            query: ORDER_QUERY,
            variables: { orderProductionJobId }
          })
          .then(
            ({
              data: {
                node: {
                  order: { id }
                }
              }
            }) => {
              markOrderAsStockStaged({ variables: { orderId: id } })
                .then(
                  ({
                    data: {
                      markOrderAsStockStaged: { succeeded, errors }
                    }
                  }) => {
                    if (succeeded) {
                      showSuccessSnackbar("Order has been staged.");
                    } else {
                      showErrorSnackbar(errors.orderId.join(", "));
                    }
                  }
                )
                .catch(e => {
                  showErrorSnackbar(e.message);
                });
            }
          );
      },

    handleMarkOrderAsUnstaged:
      ({
        client,
        markOrderAsUnstaged,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      orderProductionJobId => {
        client
          .query({
            query: ORDER_QUERY,
            variables: { orderProductionJobId }
          })
          .then(
            ({
              data: {
                node: {
                  order: { id }
                }
              }
            }) => {
              markOrderAsUnstaged({ variables: { orderId: id } })
                .then(
                  ({
                    data: {
                      markOrderAsUnstaged: { succeeded, errors }
                    }
                  }) => {
                    if (succeeded) {
                      showSuccessSnackbar("Order has been unstaged.");
                    } else {
                      showErrorSnackbar(errors.orderId.join(", "));
                    }
                  }
                )
                .catch(e => {
                  showErrorSnackbar(e.message);
                });
            }
          );
      },

    handleMarkOrderAsTransfersStandardPrintingCompleted:
      ({
        client,
        markOrderAsTransfersStandardManualPrintingCompleted,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      orderProductionJobId => {
        client
          .query({
            query: ORDER_QUERY,
            variables: { orderProductionJobId }
          })
          .then(
            ({
              data: {
                node: {
                  order: { id }
                }
              }
            }) => {
              markOrderAsTransfersStandardManualPrintingCompleted({ variables: { orderId: id } })
                .then(
                  ({
                    data: {
                      markOrderAsTransfersStandardManualPrintingCompleted: { succeeded, errors }
                    }
                  }) => {
                    if (succeeded) {
                      showSuccessSnackbar(
                        "Order has completed Transfers Standard - Printing Completed."
                      );
                    } else {
                      showErrorSnackbar(errors.orderId.join(", "));
                    }
                  }
                )
                .catch(e => {
                  showErrorSnackbar(e.message);
                });
            }
          );
      },

    handleMarkOrderAsTransfersGlitterPrintingCompleted:
      ({
        client,
        markOrderAsTransfersGlitterManualPrintingComplete,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      orderProductionJobId => {
        client
          .query({
            query: ORDER_QUERY,
            variables: { orderProductionJobId }
          })
          .then(
            ({
              data: {
                node: {
                  order: { id }
                }
              }
            }) => {
              markOrderAsTransfersGlitterManualPrintingComplete({ variables: { orderId: id } })
                .then(
                  ({
                    data: {
                      markOrderAsTransfersGlitterManualPrintingCompleted: { succeeded, errors }
                    }
                  }) => {
                    if (succeeded) {
                      showSuccessSnackbar(
                        "Order has completed Transfers Glitter - Printing Completed."
                      );
                    } else {
                      showErrorSnackbar(errors.orderId.join(", "));
                    }
                  }
                )
                .catch(e => {
                  showErrorSnackbar(e.message);
                });
            }
          );
      },
    
    handleMarkOrderAsTransfersUVPrintingCompleted:
      ({
        client,
        markOrderAsTransfersUVManualPrintingComplete,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      orderProductionJobId => {
        client
          .query({
            query: ORDER_QUERY,
            variables: { orderProductionJobId }
          })
          .then(
            ({
              data: {
                node: {
                  order: { id }
                }
              }
            }) => {
              markOrderAsTransfersUVManualPrintingComplete({ variables: { orderId: id } })
                .then(
                  ({
                    data: {
                      markOrderAsTransfersUvManualPrintingCompleted: { succeeded, errors }
                    }
                  }) => {
                    if (succeeded) {
                      showSuccessSnackbar(
                        "Order has completed Transfers UV - Printing Completed."
                      );
                    } else {
                      showErrorSnackbar(errors.orderId.join(", "));
                    }
                  }
                )
                .catch(e => {
                  showErrorSnackbar(e.message);
                });
            }
          );
      },

    handleMarkOrderAsTransfersStandardTrimmingCompleted:
      ({
        client,
        markOrderAsTransfersStandardTrimmingCompleted,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      orderProductionJobId => {
        client
          .query({
            query: ORDER_QUERY,
            variables: { orderProductionJobId }
          })
          .then(
            ({
              data: {
                node: {
                  order: { id }
                }
              }
            }) => {
              markOrderAsTransfersStandardTrimmingCompleted({ variables: { orderId: id } })
                .then(
                  ({
                    data: {
                      markOrderAsTransfersStandardTrimmingCompleted: { succeeded, errors }
                    }
                  }) => {
                    if (succeeded) {
                      showSuccessSnackbar(
                        "Order has completed Transfers Standard - Trimming Completed."
                      );
                    } else {
                      showErrorSnackbar(errors.orderId.join(", "));
                    }
                  }
                )
                .catch(e => {
                  showErrorSnackbar(e.message);
                });
            }
          );
      },

    handleMarkOrderAsTransfersGlitterTrimmingCompleted:
      ({
        client,
        markOrderAsTransfersGlitterTrimmingCompleted,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      orderProductionJobId => {
        client
          .query({
            query: ORDER_QUERY,
            variables: { orderProductionJobId }
          })
          .then(
            ({
              data: {
                node: {
                  order: { id }
                }
              }
            }) => {
              markOrderAsTransfersGlitterTrimmingCompleted({ variables: { orderId: id } })
                .then(
                  ({
                    data: {
                      markOrderAsTransfersGlitterTrimmingCompleted: { succeeded, errors }
                    }
                  }) => {
                    if (succeeded) {
                      showSuccessSnackbar(
                        "Order has completed Transfers Glitter - Trimming Completed."
                      );
                    } else {
                      showErrorSnackbar(errors.orderId.join(", "));
                    }
                  }
                )
                .catch(e => {
                  showErrorSnackbar(e.message);
                });
            }
          );
      },
    
    handleMarkOrderAsTransfersUVTrimmingCompleted:
      ({
        client,
        markOrderAsTransfersUVTrimmingCompleted,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      orderProductionJobId => {
        client
          .query({
            query: ORDER_QUERY,
            variables: { orderProductionJobId }
          })
          .then(
            ({
              data: {
                node: {
                  order: { id }
                }
              }
            }) => {
              markOrderAsTransfersUVTrimmingCompleted({ variables: { orderId: id } })
                .then(
                  ({
                    data: {
                      markOrderAsTransfersUvTrimmingCompleted: { succeeded, errors }
                    }
                  }) => {
                    if (succeeded) {
                      showSuccessSnackbar(
                        "Order has completed Transfers UV - Trimming Completed."
                      );
                    } else {
                      showErrorSnackbar(errors.orderId.join(", "));
                    }
                  }
                )
                .catch(e => {
                  showErrorSnackbar(e.message);
                });
            }
          );
      },

    handleMarkOrderProductionJobAsStockPulled:
      ({
        markOrderProductionJobAsStockPulled,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      orderProductionJobId => {
        markOrderProductionJobAsStockPulled({
          variables: { orderProductionJobId }
        })
          .then(
            ({
              data: {
                markOrderProductionJobAsStockPulled: { succeeded, errors }
              }
            }) => {
              if (succeeded) {
                showSuccessSnackbar("Order production job has been pulled.");
              } else {
                showErrorSnackbar(errors.orderId.join(", "));
              }
            }
          )
          .catch(e => {
            showErrorSnackbar(e.message);
          });
      },

    handleProductionJobScan:
      ({ client, setLastOrderProductionJobScanned }) =>
      orderProductionJobId => {
        client
          .query({
            query: gql`
              query ProductionJobBarcode($orderProductionJobId: ID!) {
                node(id: $orderProductionJobId) {
                  ... on OrderProductionJob {
                    id
                    order {
                      id
                    }
                  }
                }
              }
            `,
            variables: { orderProductionJobId }
          })
          .then(({ data: { node } }) => {
            if (node) {
              setLastOrderProductionJobScanned({
                id: node.id,
                order: {
                  id: node.order.id
                }
              });
            }
          });
      },

    handleSetStockLocationParent:
      ({ setStockLocationParent, showErrorSnackbar, showSuccessSnackbar }) =>
      (location, parentLocation) => {
        setStockLocationParent({
          variables: { location: location, parentLocation: parentLocation }
        })
          .then(
            ({
              data: {
                setStockLocationParent: { succeeded, errors }
              }
            }) => {
              if (succeeded) {
                showSuccessSnackbar(
                  "Parent stock container location has been set"
                );
                playAudioForSuccess();
              } else {
                showErrorSnackbar(
                  [
                    ...errors.location.map(error => `Location: ${error}`),
                    ...errors.parentLocation.map(
                      error => `ParentLocation: ${error}`
                    )
                  ].join(", ")
                );
              }
            }
          )
          .catch(error => {
            showErrorSnackbar(error.message);
          });
      },

    handleStockContainerScan:
      ({ client, setLastStockContainerScanned }) =>
      stockContainerId => {
        client
          .query({
            query: gql`
              query StockContainerBarcode($stockContainerId: ID!) {
                node(id: $stockContainerId) {
                  ... on StockContainer {
                    id
                    orderProductionJob {
                      id
                      order {
                        id
                      }
                    }
                  }
                }
              }
            `,
            variables: { stockContainerId }
          })
          .then(({ data: { node } }) => {
            if (node) {
              setLastStockContainerScanned(node);
            }
          });
      },

    handleDirectToGarmentCustomizationAreaScan:
      ({ client, setLastOrderProductionJobScanned }) =>
      orderProductionJobCustomizationAreaId => {
        client
          .query({
            query: gql`
              query DTGBarcode($orderProductionJobCustomizationAreaId: ID!) {
                node(id: $orderProductionJobCustomizationAreaId) {
                  ... on OrderProductionJobCustomizationArea {
                    id
                    productionJob {
                      id
                      directToGarmentStatus
                      lineItemBreakdownByProduct {
                        lineItems {
                          id
                          kornitConfigurations {
                            id
                            name
                            colorOutputProfile
                            variant
                          }
                        }
                      }
                      order {
                        dueBy {
                          date
                        }
                        primaryCustomer {
                          id
                          name(format: FIRST_NAME_LAST_NAME)
                        }
                        id
                      }
                    }
                  }
                }
              }
            `,
            variables: { orderProductionJobCustomizationAreaId }
          })
          .then(({ data: { node } }) => {
            if (node.productionJob.directToGarmentStatus === "NOT_APPLICABLE") {
              return;
            }
            let configurations = null;
            let includeConfigurations = true;
            node.productionJob.lineItemBreakdownByProduct.forEach(
              lineItemBreakdownByProduct => {
                lineItemBreakdownByProduct.lineItems.forEach(lineItem => {
                  if (lineItem.kornitConfigurations.length === 0) {
                    includeConfigurations = false;
                  } else if (configurations == null) {
                    configurations = lineItem.kornitConfigurations.map(
                      ({ variant, colorOutputProfile }) => ({
                        variant,
                        colorOutputProfile
                      })
                    );
                  }
                });
              }
            );
            setLastOrderProductionJobScanned({
              id: node.productionJob.id,
              order: {
                id: node.productionJob.order.id
              }
            });
            const orderId = new Buffer(node.productionJob.order.id, "base64")
              .toString("ascii")
              .substring("Order:".length);
            const customizationAreaId = new Buffer(node.id, "base64")
              .toString("ascii")
              .substring("OrderProductionJobCustomizationArea:".length);
            const createdAt = DateTime.fromISO(
              node.productionJob.order.dueBy.date
            );
            const base = createdAt.year > 2016 ? "Orders" : `${createdAt.year}`;
            const customerFullName =
              node.productionJob.order.primaryCustomer.name
                .replace(":", "")
                .replace(".", "")
                .replace(",", "")
                .trim();
            const customerFirstInitial = customerFullName[0].toUpperCase();
            const path = `/${base}/${customerFirstInitial}/${customerFullName}/${orderId}/`;
            const command = JSON.stringify({
              type: "COMMAND",
              name: "dtgv1",
              data: {
                orderId: orderId,
                customizationAreaId: customizationAreaId,
                path: path,
                configurations: includeConfigurations ? configurations : []
              }
            });

            navigator.clipboard.writeText(command);
          });
      }
  }),

  withHandlers({
    handleBarcodeScan:
      ({
        handleBarcodeError,
        handleDirectToGarmentCustomizationAreaScan,
        handleMarkOrderAsDtgCompleted,
        handleMarkOrderAsDtgStarted,
        handleMarkOrderAsHighDefinitionDigitalCompleted,
        handleMarkOrderAsHighDefinitionDigitalLocated,
        handleMarkOrderAsHighDefinitionDigitalPaused,
        handleMarkOrderAsHighDefinitionDigitalStarted,
        handleMarkOrderAsHighDefinitionDigitalTrimmed,
        handleMarkOrderAsNoLongerOnHold,
        handleMarkOrderAsOnHold,
        handleMarkOrderAsScreenPrintingCompleted,
        handleMarkOrderAsScreenPrintingStarted,
        handleMarkOrderAsTransfersStandardPrintingCompleted,
        handleMarkOrderAsTransfersGlitterPrintingCompleted,
        handleMarkOrderAsTransfersUVPrintingCompleted,
        handleMarkOrderAsTransfersStandardTrimmingCompleted,
        handleMarkOrderAsTransfersGlitterTrimmingCompleted,
        handleMarkOrderAsTransfersUVTrimmingCompleted,
        handleMarkOrderAsStockStaged,
        handleMarkOrderAsUnstaged,
        handleMarkOrderProductionJobAsCountValidated,
        handleMarkOrderAsTagPrintCompleted,
        handleMarkOrderProductionJobAsStockPulled,
        handleProductionJobScan,
        handleRecordOrderProductionJobBoxLocation,
        handleRecordOrderProductionJobScreenLocation,
        handleRecordStockContainerLocation,
        handleSetStockLocationParent,
        handleStockContainerScan,
        resetScannedObjects,
        scannedObjects,
        showErrorSnackbar
      }) =>
      barcode => {
        console.log("Barcode Scan"); // eslint-disable-line no-console
        console.log("Raw data"); // eslint-disable-line no-console
        console.log(barcode); // eslint-disable-line no-console
        try {
          if (barcode.toLowerCase().startsWith("boxlocation:")) {
            const name = barcode.split(":")[1];
            barcode = JSON.stringify({
              variant: "LOCATION",
              type: "box",
              name
            });
          } else if (barcode.toLowerCase().startsWith("screenlocation:")) {
            const name = barcode.split(":")[1];
            barcode = JSON.stringify({
              variant: "LOCATION",
              type: "screen",
              name
            });
          }

          const data = JSON.parse(barcode);
          const currentTimestamp = Math.floor(new Date().getTime() / 1000.0);
          console.log("Decoded Data"); // eslint-disable-line no-console
          console.log(data); // eslint-disable-line no-console
          console.log("Current Scanned Objects"); // eslint-disable-line no-console
          console.log(scannedObjects); // eslint-disable-line no-console

          const args = scannedObjects
            .filter((scannedObject, index, allSCannedObjects) => {
              return (
                !scannedObject.id ||
                allSCannedObjects.map(b => b.id).indexOf(scannedObject.id) ===
                  index
              );
            })
            .filter(
              scannedObject => scannedObject.timestamp + 10 >= currentTimestamp
            );
          console.log("args"); // eslint-disable-line no-console
          console.log(args); // eslint-disable-line no-console
          const firstScannedObject = args[0];
          console.log("firstScannedObject"); // eslint-disable-line no-console
          console.log(firstScannedObject); // eslint-disable-line no-console

          if (data.variant === "OBJECT" && data.type && data.id) {
            data.timestamp = currentTimestamp;
            scannedObjects.push(data);
            if (data.type === "OrderProductionJobCustomizationArea") {
              handleDirectToGarmentCustomizationAreaScan(data.id);
            } else if (data.type === "OrderProductionJob") {
              handleProductionJobScan(data.id);
            } else if (data.type === "StockContainer") {
              handleStockContainerScan(data.id);
            }
          } else if (
            data.variant === "ACTION" &&
            data.action &&
            data.arguments &&
            data.arguments.length > 0
          ) {
            if (data.action === "resetScannedObjects") {
              resetScannedObjects();
              return;
            }

            if (args.length !== data.arguments.length) {
              handleBarcodeError();
              return;
            }

            const argsType = args.map(argument => {
              return argument.type;
            });

            const dataArgsType = data.arguments.map(argument => {
              return argument.type;
            });

            if (argsType.sort()[0] !== dataArgsType.sort()[0]) {
              handleBarcodeError();
              return;
            }

            if (data.action === "markOrderAsStockStaged") {
              handleMarkOrderAsStockStaged(firstScannedObject.id);
            } else if (data.action === "markOrderAsUnstaged") {
              handleMarkOrderAsUnstaged(firstScannedObject.id);
            } else if (data.action === "markOrderAsPulledFromStock") {
              handleMarkOrderProductionJobAsStockPulled(firstScannedObject.id);
            } else if (data.action === "markOrderAsDtgStarted") {
              handleMarkOrderAsDtgStarted(firstScannedObject.id);
            } else if (data.action === "markOrderAsDtgCompleted") {
              handleMarkOrderAsDtgCompleted(firstScannedObject.id);
            } else if (data.action === "markOrderAsScreenPrintingStarted") {
              handleMarkOrderAsScreenPrintingStarted(firstScannedObject.id);
            } else if (data.action === "markOrderAsScreenPrintingCompleted") {
              handleMarkOrderAsScreenPrintingCompleted(firstScannedObject.id);
            }  else if (data.action === "markOrderAsTransfersStandardManualPrintingCompleted") {
              handleMarkOrderAsTransfersStandardPrintingCompleted(firstScannedObject.id);
            } else if (data.action === "markOrderAsTransfersGlitterManualPrintingCompleted") {
              handleMarkOrderAsTransfersGlitterPrintingCompleted(firstScannedObject.id);
            } else if (data.action === "markOrderAsTransfersUVManualPrintingCompleted") {
              handleMarkOrderAsTransfersUVPrintingCompleted(firstScannedObject.id);
            } else if (data.action === "markOrderAsTransfersStandardTrimmingCompleted") {
              handleMarkOrderAsTransfersStandardTrimmingCompleted(firstScannedObject.id);
            } else if (data.action === "markOrderAsTransfersGlitterTrimmingCompleted") {
              handleMarkOrderAsTransfersGlitterTrimmingCompleted(firstScannedObject.id);
            } else if (data.action === "markOrderAsTransfersUVTrimmingCompleted") {
              handleMarkOrderAsTransfersUVTrimmingCompleted(firstScannedObject.id);
            } else if (
              data.action === "markOrderProductionJobAsCountValidated"
            ) {
              handleMarkOrderProductionJobAsCountValidated(
                firstScannedObject.id
              );
            } else if (data.action === "markOrderAsTagPrintCompleted") {
              handleMarkOrderAsTagPrintCompleted(firstScannedObject.id);
            } else if (
              data.action === "markOrderAsHighDefinitionDigitalCompleted"
            ) {
              handleMarkOrderAsHighDefinitionDigitalCompleted(
                firstScannedObject.id
              );
            } else if (
              data.action === "markOrderAsHighDefinitionDigitalStarted"
            ) {
              handleMarkOrderAsHighDefinitionDigitalStarted(
                firstScannedObject.id
              );
            } else if (
              data.action === "markOrderAsHighDefinitionDigitalTrimmed"
            ) {
              handleMarkOrderAsHighDefinitionDigitalTrimmed(
                firstScannedObject.id
              );
            } else if (
              data.action === "markOrderAsHighDefinitionDigitalLocated"
            ) {
              handleMarkOrderAsHighDefinitionDigitalLocated(
                firstScannedObject.id
              );
            } else if (
              data.action === "markOrderAsHighDefinitionDigitalPaused"
            ) {
              handleMarkOrderAsHighDefinitionDigitalPaused(
                firstScannedObject.id
              );
            } else if (data.action === "markOrderAsNoLongerOnHold") {
              handleMarkOrderAsNoLongerOnHold(firstScannedObject.id);
            } else if (data.action === "markOrderAsOnHold") {
              handleMarkOrderAsOnHold(firstScannedObject.id);
            } else {
              showErrorSnackbar("Error unknown action");
            }
            resetScannedObjects();
          } else if (data.variant === "LOCATION" && data.type && data.name) {
            data.timestamp = currentTimestamp;
            scannedObjects.push(data);
            if (
              args.length !== 1 &&
              firstScannedObject.type !== "OrderProductionJob" &&
              firstScannedObject.type !== "StockContainer" &&
              firstScannedObject.type !== "StockContainerLocation"
            ) {
              showErrorSnackbar("Error nothing to associate with location");
              return;
            }
            if (firstScannedObject.type === "OrderProductionJob") {
              let handleRecordOrderProductionJobLocation = null;
              switch (data.type.toLowerCase()) {
                case "box":
                  handleRecordOrderProductionJobLocation =
                    handleRecordOrderProductionJobBoxLocation;
                  break;
                case "screen":
                  handleRecordOrderProductionJobLocation =
                    handleRecordOrderProductionJobScreenLocation;
                  break;
                default:
                  break;
              }
              if (handleRecordOrderProductionJobLocation) {
                handleRecordOrderProductionJobLocation(
                  data.name,
                  firstScannedObject.id
                );
              }
            } else if (firstScannedObject.type === "StockContainer") {
              handleRecordStockContainerLocation(
                data.name,
                firstScannedObject.id
              );
            } else if (firstScannedObject.variant === "LOCATION") {
              handleSetStockLocationParent(firstScannedObject.name, data.name);
            }

            resetScannedObjects();
          } else {
            handleBarcodeError();
          }
        } catch (xxx) {
          console.log("Error decoding barcode"); // eslint-disable-line no-console
          console.log(xxx); // eslint-disable-line no-console
        }
      }
  })
);

const BarcodePivot = ({
  children,
  handleBarcodeError,
  handleBarcodeScan,
  lastOrderProductionJobScanned,
  lastStockContainerScanned,
  resetLastOrderProductionJobScanned,
  resetLastStockContainerScanned
}) => (
  <React.Fragment>
    <BarcodePivotContext.Provider
      value={{
        lastOrderProductionJobScanned,
        lastStockContainerScanned,
        acknowledgeOrderProductionJob: resetLastOrderProductionJobScanned,
        acknowledgeStockContainer: resetLastStockContainerScanned
      }}
    >
      {children}
    </BarcodePivotContext.Provider>
    <BarcodeReader onError={handleBarcodeError} onScan={handleBarcodeScan} />
  </React.Fragment>
);

export default enhancer(BarcodePivot);

export const BarcodePivotContextConsumer = BarcodePivotContext.Consumer;
