// @flow

import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { fragments as transfersUVOrderStatusFragments } from "../../application/apps/TransferPrintingApp/components/TransfersUVOrderStatus/graph";

const markOrderAsTransfersUVTrimmingCompleted: any = graphql(
  gql`
    mutation($orderId: ID!) {
      markOrderAsTransfersUvTrimmingCompleted(orderId: $orderId) {
        errors {
          orderId
        }
        updatedOrder {
          ...TransfersUVOrderStatus_order
        }
        succeeded
      }
    }
    ${transfersUVOrderStatusFragments.order}
  `,
  {
    name: "markOrderAsTransfersUVTrimmingCompleted"
  }
);

export default markOrderAsTransfersUVTrimmingCompleted;
